import React from 'react'
import Button from '@material-ui/core/Button'
import usePostListMutation from '~/hooks/usePostListMutation'

const SavePostList = () => {
  const [mutate, { loading }, [disabled]] = usePostListMutation()
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={mutate}
      disabled={loading || disabled}
    >
      Sauvegarder
    </Button>
  )
}

export default SavePostList
