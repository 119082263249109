import React from 'react'
import MenuSideBar from '~/components/MenuSideBar'
import Subnav from '~/components/Subnav'
import Box from '@material-ui/core/Box'
import { ZINDEX_MENU_AND_HEADER } from '~/constantes'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  menu: {
    [theme.breakpoints.down('sm')]: {
      margin: [['auto', 1]],
      padding: [['auto', 3]],
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

const MenuAndHeader = () => {
  const classes = useStyles()
  return (
    <Box
      position="sticky"
      top={0}
      zIndex={ZINDEX_MENU_AND_HEADER}
      bgcolor="background.default"
    >
      <div className={classes.menu}>
        <MenuSideBar />
      </div>
      <Subnav />
    </Box>
  )
}

export default MenuAndHeader
