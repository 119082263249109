import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { updateMutatePostList } from '~/components/StoryEditor/queries'
import {
  updateMutatePostListVariables,
  updateMutatePostList as updateMutatePostListData,
} from '~/queries'
import { useDispatch } from 'react-redux'
import { useSelector } from '~/reducers'
import { open as openToast } from '~/ducks/toast'
import {
  setPostListMutationResult,
  selectPostListMutationDisabled,
  setPostListMutationDisabledForReason,
} from '~/ducks/editor'

export enum DISABLED_REASON {
  UPLOADING_FILE,
}

type Signature = () => [
  () => void,
  any,
  [boolean, (reason: DISABLED_REASON, value: boolean) => any]
]

const usePostListMutation: Signature = () => {
  const editorPostList = useSelector((state) => state.editor.postList)
  const postListMutation = useSelector((state) => state.editor.postListMutation)
  const disabled = useSelector(selectPostListMutationDisabled)

  const [mutate, mutationResult] = useMutation<
    updateMutatePostListData,
    updateMutatePostListVariables
  >(updateMutatePostList)
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(setPostListMutationResult(mutationResult))
  }, [mutationResult, dispatch])

  const submit = () => {
    if (editorPostList) {
      mutate({
        variables: {
          id: editorPostList.id,
          ...editorPostList,
        },
      }).then(({ data }) => {
        if (data && data.updatePostList && data.updatePostList.errors) {
          dispatch(
            openToast({
              message: `Erreur: ${([] as any[])
                .concat(
                  ...data.updatePostList.errors.map((e) => e && e.messages)
                )
                .join(' ')}`,
              variant: 'error',
            })
          )
        }
      })
    }
  }

  const setDisabledFor = (reason: DISABLED_REASON, value: boolean) =>
    dispatch(setPostListMutationDisabledForReason(reason, value))

  return [submit, postListMutation.mutationResult, [disabled, setDisabledFor]]
}

export default usePostListMutation
