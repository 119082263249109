import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Logo from '../../public/static/logo.png'
import ButtonLink from '~/components/ButtonLink'
import { WithDarkTheme } from '~/theme'
import zebrockLogo from '~/assets/melo_zebLogo-white.png'
import { useTranslation } from '~/i18n'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    minHeight: '30vh',
    color: 'white',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'inset 0 20px 0 rgba(0,0,0, .1)',
    position: 'relative',
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    '& > *:not(:last-child)': {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  homeLink: {
    marginRight: theme.spacing(3),
  },
  link: {
    display: 'block',
    marginLeft: 0 - theme.spacing(2),
    fontSize: '1.2rem',
  },
  inverseColor: {
    filter: 'invert(100%)',
  },
  zebrockLogo: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
}))

export default function Footer() {
  const classes = useStyles()
  const { t } = useTranslation('common')
  return (
    <WithDarkTheme>
      <div className={classes.root}>
        <ButtonLink href="/">
          <img
            width={70}
            src={Logo}
            alt="Logo"
            className={classes.inverseColor}
          />
        </ButtonLink>
        <div>
          <Button component={ButtonLink} href="/" className={classes.link}>
            {t('Accueil')}
          </Button>
          <Button
            component={ButtonLink}
            href="/recherche"
            className={classes.link}
          >
            {t('Recherche')}
          </Button>
          <Button component={ButtonLink} href="/login" className={classes.link}>
            {t("S'inscrire")}
          </Button>
          <Button component={ButtonLink} href="/login" className={classes.link}>
            {t('Se connecter')}
          </Button>
          <Button component={ButtonLink} href="/moi" className={classes.link}>
            {t('Mon profil')}
          </Button>
        </div>
        <div>
          <Button
            className={classes.link}
            component={ButtonLink}
            href="/a-propos"
          >
            {t('À propos')}
          </Button>
          <Button
            className={classes.link}
            component={ButtonLink}
            href="/feedback"
          >
            {t('Nous contacter')}
          </Button>
        </div>
        <div className={classes.zebrockLogo}>
          <img src={zebrockLogo} width={140} alt="Composé par Zebrock" />
        </div>
      </div>
    </WithDarkTheme>
  )
}
