import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import BuildIcon from '@material-ui/icons/Build'
import FeedbackIcon from '@material-ui/icons/Feedback'
import CreateIcon from '@material-ui/icons/Create'
import HomeIcon from '@material-ui/icons/Home'
import FdlmIcon from '@material-ui/icons/WbSunny'
import HelpIcon from '@material-ui/icons/Help'
import LanguageIcon from '@material-ui/icons/Language'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import SearchIcon from '@material-ui/icons/Search'
import ButtonLink from '~/components/ButtonLink'
import gql from 'graphql-tag'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import * as authActions from '~/ducks/auth'
import * as searchActions from '~/ducks/search'
import Logo from '../../public/static/logo.png'
import zebrockLogo from '~/assets/melo_zebLogo-white.png'
import { fontSecondary } from '~/theme'
import { MENU_BAR_HEIGHT } from '~/constantes'
import { useDispatch } from '~/reducers'
import { CurrentUserForMenu } from '~/queries'
import useCurrentUserQuery from '~/hooks/useCurrentUserQuery'
import useI18n from '~/hooks/useI18n'
import { useTranslation } from '~/i18n'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: 300,
    maxWidth: '70vw',
    height: '100%',
    '& svg': { color: theme.palette.common.white },
  },
  homeLink: {
    textDecoration: 'none',
    paddingRight: theme.spacing(3),
    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
    fontFamily: theme.typography.fontSecondary,
    letterSpacing: '0.06em',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    height: MENU_BAR_HEIGHT,
    color: theme.palette.common.white,
    '& span, & img': {
      filter: 'invert(100%)',
    },
  },
  beta: {
    fontSize: '.8rem',
    verticalAlign: 'super',
    color: theme.palette.common.black,
  },
  logo: {
    marginRight: theme.spacing(1),
  },
}))

const currentUserQuery = gql`
  query CurrentUserForMenu {
    currentUser {
      id
      slug
      avatar
      isStaff
      groups {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

const MenuListItem = ({ itemProps, icon, label }: any) => {
  return (
    <ListItem button {...itemProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  )
}
const MenuSideBar = () => {
  const classes = useStyles()
  const [openState, setOpenState] = React.useState(false)
  const dispatch = useDispatch()
  const { language, setLanguage } = useI18n()
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <Drawer
        open={openState}
        onClose={() => setOpenState(false)}
        anchor="left"
        classes={{
          paper: classes.root,
        }}
      >
        <div
          tabIndex={0}
          role="button"
          style={{ height: '100%' }}
          onClick={() => setOpenState(false)}
          onKeyDown={() => setOpenState(false)}
          onMouseEnter={() => setOpenState(true)}
          onMouseLeave={() => setOpenState(false)}
        >
          <Box
            color="common.white"
            display="flex"
            flexDirection="column"
            minHeight="100vh"
          >
            <Box flexGrow={1}>
              <ListItem
                component={ButtonLink}
                href="/"
                className={classes.homeLink}
              >
                <img
                  width={21}
                  src={Logo}
                  alt="Logo"
                  className={classes.logo}
                />{' '}
                <Typography
                  component="div"
                  style={{ fontFamily: fontSecondary, fontWeight: 700 }}
                >
                  Mélo<span className={classes.beta}>Beta</span>
                </Typography>
              </ListItem>
              <List>
                {[
                  {
                    label: t('Accueil'),
                    icon: <HomeIcon />,
                    itemProps: {
                      component: ButtonLink,
                      href: '/',
                    },
                  },
                  {
                    label: t('Recherche'),
                    icon: <SearchIcon />,
                    itemProps: {
                      onClick: () => dispatch(searchActions.show()),
                    },
                  },
                ].map((props) => (
                  <MenuListItem key={props.label} {...props} />
                ))}
                <CurrentUserBlock />
                <Divider />
                <ListItem button component={ButtonLink} href="/feedback">
                  <ListItemIcon>
                    <FeedbackIcon />
                  </ListItemIcon>
                  <ListItemText>{t('Donner son avis')}</ListItemText>
                </ListItem>
                <ListItem button component={ButtonLink} href="/a-propos">
                  <ListItemIcon>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText>{t('À propos')}</ListItemText>
                </ListItem>
                {[
                  {
                    code: 'fr',
                    display: 'Français',
                  },
                  {
                    code: 'en',
                    display: 'English',
                  },
                  {
                    code: 'es',
                    display: 'Español',
                  },
                ]
                  .filter((ln) => ln.code !== language)
                  .map((ln) => (
                    <ListItem
                      button
                      onClick={() => setLanguage(ln.code)}
                      key={ln.code}
                    >
                      <ListItemIcon>
                        <LanguageIcon />
                      </ListItemIcon>
                      <ListItemText>{ln.display}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            </Box>
            <Box textAlign="center">
              <a
                href="http://zebrock.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={zebrockLogo} width={140} alt="Composé par Zebrock" />
              </a>
            </Box>
          </Box>
        </div>
      </Drawer>
      <div>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => setOpenState(true)}>
            <MenuIcon />
          </IconButton>
          <Button component={ButtonLink} href="/">
            <img width={21} src={Logo} alt="Logo" className={classes.logo} />{' '}
            <Typography
              component="div"
              style={{ fontFamily: fontSecondary, fontWeight: 700 }}
            >
              Mélo
            </Typography>
          </Button>
        </Box>
      </div>
    </React.Fragment>
  )
}

export default MenuSideBar

const CurrentUserBlock = () => {
  const dispatch = useDispatch()
  const { data } = useCurrentUserQuery<CurrentUserForMenu>(currentUserQuery)
  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <Divider />
      {(data?.currentUser
        ? [
            {
              label: t('Mon profil'),
              icon: data.currentUser.avatar && (
                <Avatar
                  style={{ width: 24, height: 24 }}
                  src={data.currentUser.avatar}
                />
              ),
              itemProps: {
                component: ButtonLink,
                href: '/moi',
              },
            },
            {
              label: t('Paramètres'),
              icon: <BuildIcon />,
              itemProps: {
                component: ButtonLink,
                href: '/edit-profile',
              },
            },
            ...(process.env.ENABLE_EDITOR
              ? [
                  {
                    label: t('Créer un parcours'),
                    icon: <CreateIcon />,
                    itemProps: {
                      component: ButtonLink,
                      href: '/new-story',
                    },
                  },
                ]
              : []),
            ...(data.currentUser.isStaff ||
            data.currentUser.groups.edges.filter(
              (edge) => edge?.node?.name === 'IF'
            ).length > 0
              ? [
                  {
                    label: t('Fête de la Musique (EDITEUR)'),
                    icon: <FdlmIcon />,
                    itemProps: {
                      component: ButtonLink,
                      href: '/fdlm-editor',
                    },
                  },
                ]
              : []),
            ...(data.currentUser.isStaff
              ? [
                  {
                    label: t('Admin'),
                    icon: <WhatshotIcon />,
                    itemProps: {
                      component: 'a',
                      href: '/meladmin/',
                    },
                  },
                ]
              : []),
            {
              label: t('Se déconnecter'),
              icon: <ExitToAppIcon />,
              itemProps: {
                onClick: () => dispatch(authActions.reset()),
              },
            },
          ]
        : [
            {
              label: t('Se connecter'),
              icon: <AccountCircleIcon />,
              itemProps: {
                component: ButtonLink,
                href: '/login',
              },
            },
          ]
      )
        .filter((o) => !!o)
        .map((props) => (
          <MenuListItem key={props.label} {...props} />
        ))}
    </React.Fragment>
  )
}
