import React from 'react'
import Link from '~/components/Link'
import LinkType from 'next/link'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
  },
}))

const ButtonLink = React.forwardRef<LinkType, any>((props, ref) => {
  const classes = useStyles()
  return (
    <Link href={props.href} as={props.as} prefetch={props.prefetch} ref={ref}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={clsx(classes.link, props.className)} style={props.style}>
        {props.children}
      </a>
    </Link>
  )
})

export default ButtonLink
