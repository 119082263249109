import React from 'react'
import Button from '@material-ui/core/Button'
import SavePostList from '~/components/StoryEditor/pages/Posts/SavePostListButton'
import ButtonLink from '~/components/ButtonLink'

interface Props {
  type: 'savePostList' | 'internalLink' | 'link'
  label?: string
}

const Action = ({ type, label, ...props }: Props) => {
  switch (type) {
    case 'savePostList':
      return <SavePostList />
    case 'internalLink':
      return (
        <Button component={ButtonLink} {...props}>
          {label}
        </Button>
      )

    case 'link':
      return <Button {...props}>{label}</Button>
    default:
      break
  }
}

export default Action
