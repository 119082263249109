import gql from 'graphql-tag'

export const postListQuery = gql`
  query getPostListEditor($slug: String!) {
    postList(slug: $slug) {
      id
      slug
      name
      isPublic
      description
      thumbnail
      abstract
      originalArtwork
      originalBackground
      artwork {
        id
        renditions {
          thumbnail {
            id
            href
            width
            height
          }
        }
      }
      background {
        id
        renditions {
          big {
            id
            href
          }
          mobile {
            id
            href
          }
        }
      }
      displayMap
    }
  }
`

const linkToEntityCommonProps = `
id
slug
name
thumbnail
`

export const entityPropsForLinkToEntity = `
... on Person {
  ${linkToEntityCommonProps}
  startDate: birthDate
  endDate: deathDate
  locationName: birthPlace
}
... on Group {
  ${linkToEntityCommonProps}
  startDate
  endDate
  locationName: birthPlace
}
... on Track {
  ${linkToEntityCommonProps}
  startDate: date
  bands {
    edges {
      node {
        id
        name
      }
    }
  }
  authors {
    edges {
      node {
        id
        name
      }
    }
  }
}
... on Instrument {
  ${linkToEntityCommonProps}
}
... on Tag {
  ${linkToEntityCommonProps}
  startDate
  endDate
  locationName
}
... on Style {
  ${linkToEntityCommonProps}
  styleStartDate: startDate
  endDate
  locationName
}
... on PostList {
  ${linkToEntityCommonProps}
}
`

const post = `
id
name
postType
hasVisualContent
mediaUrl
duration
mediaFile
thumbnail
html
metadata
body
locationName
startDate
endDate
authorFreetext
created
updated
linkToEntity {
  ${entityPropsForLinkToEntity}
}
artwork {
  id
  renditions {
    medium {
      id
      href
      height
      width
    }
  }
}
`
export const postListPostsQuery = gql`
  query getPostsForPostListEditor($slug: String!) {
    postList(slug: $slug) {
      id
      postsWithRel {
        edges {
          node {
            id
            order
            post {
              ${post}
            }
          }
        }
      }
    }
  }
`

export const removeRelationship = gql`
  mutation removeRelationship($id: ID!) {
    removePostEntityRelationship(input: { id: $id }) {
      errors
    }
  }
`

const entityProps = `id
type
slug
name
thumbnail`

const postentityrelSet = `
postentityrelSet {
  edges {
    node {
      id
      entity {
        ... on Track {
          ${entityProps}
        }
        ... on Person {
          ${entityProps}
        }
        ... on Group {
          ${entityProps}
        }
        ... on Instrument {
          ${entityProps}
        }
          ... on Style {
          ${entityProps}
        }
        ... on Tag {
          ${entityProps}
        }
        ... on PostList {
          ${entityProps}
        }
      }
    }
  }
}`

export const mutatePost = gql`
  mutation mutateMediaPost(
    $id: String!
    $name: String
    $body: String
    $postType: String
    $mediaUrl: String
    $hasVisualContent: Boolean
    $mediaFile: String
    $locationName: String
    $startDate: Date
    $endDate: Date
    $linkToEntityId: String
  ) {
    mutatePost(
      input: {
        id: $id
        name: $name
        body: $body
        postType: $postType
        hasVisualContent: $hasVisualContent
        mediaUrl: $mediaUrl
        mediaFile: $mediaFile
        locationName: $locationName
        startDate: $startDate
        endDate: $endDate
        linkToEntityId: $linkToEntityId
      }
    ) {
      payload {
        ${post}
        ${postentityrelSet}
      }
      errors {
        messages
        field
      }
    }
  }
`

export const getPostRelationshipForEditorQuery = gql`
  query getPostRelationshipForEditor($id: ID!) {
    post(id: $id) {
      id
      ${postentityrelSet}
    }
  }
`

export const addRelationship = gql`
  mutation addPostEntityRelationship($post: ID!, $entity: ID!) {
    addPostEntityRelationship(input: { post: $post, entity: $entity }) {
      errors
      rel {
        id
      }
    }
  }
`

export const createMutatePostList = gql`
  mutation createMutatePostList($slug: String!, $name: String!) {
    createPostList(input: { slug: $slug, name: $name }) {
      payload {
        id
        slug
        name
      }
      errors {
        field
        messages
      }
    }
  }
`

export const updateMutatePostList = gql`
  mutation updateMutatePostList(
    $id: String!
    $isPublic: Boolean
    $name: String
    $description: String
    $abstract: String
    $originalArtwork: String
    $originalBackground: String
  ) {
    updatePostList(
      input: {
        id: $id
        name: $name
        isPublic: $isPublic
        description: $description
        abstract: $abstract
        originalArtwork: $originalArtwork
        originalBackground: $originalBackground
      }
    ) {
      payload {
        id
        slug
        name
        description
        abstract
        isPublic
        thumbnail
        originalArtwork
        originalBackground
        artwork {
          id
          renditions {
            thumbnail {
              id
              href
              width
              height
            }
          }
        }
        background {
          id
          renditions {
            big {
              id
              href
            }
            mobile {
              id
              href
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const mutatePostListUpdateShowMap = gql`
  mutation mutatePostListUpdateShowMap($id: String!, $displayMap: Boolean!) {
    updatePostList(input: { id: $id, displayMap: $displayMap }) {
      payload {
        id
        displayMap
      }
    }
  }
`

export const reorderPostListMutation = gql`
  mutation reorderPostListMutation($postList: ID!, $postsRelationships: [ID]!) {
    reorderPostList(
      input: { postList: $postList, postsRelationships: $postsRelationships }
    ) {
      clientMutationId
    }
  }
`

export const getPostListsEntities = gql`
  query getPostListsEntitiesForMapEditor($slug: String!) {
    postList(slug: $slug) {
      id
      displayMap
      postsWithRel {
        edges {
          node {
            id
            displayOnMap
            post {
              id
              name
              postType
              locationName
              lat
              lng
              body
              postentityrelSet {
                edges {
                  node {
                    id
                    displayOnMap
                    entity {
                      ... on Person {
                        id
                        slug
                        type
                        name
                        lat
                        lng
                        thumbnail
                      }
                      ... on Group {
                        id
                        slug
                        type
                        name
                        lat
                        lng
                        thumbnail
                      }
                      ... on Style {
                        id
                        slug
                        type
                        name
                        lat
                        lng
                        thumbnail
                      }
                      ... on Tag {
                        id
                        slug
                        type
                        name
                        lat
                        lng
                        thumbnail
                      }
                      ... on Instrument {
                        id
                      }
                      ... on PostList {
                        id
                      }
                      ... on Track {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const postEntityRelationshipMutation = gql`
  mutation mutatePostEntityRelationship($id: String!, $displayOnMap: Boolean!) {
    postEntityRelationship(input: { id: $id, displayOnMap: $displayOnMap }) {
      payload {
        id
        displayOnMap
        post {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
