import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import clsx from 'clsx'
import { connect } from 'react-redux'
import Router from '~/utils/router'
import { resetHeader, HEADER_THEMES } from '~/ducks/header'
import ChapterTitle from '~/components/Story/ChapterTitle'
import FavoriteButton from '~/components/FavoriteButton'
import BoxPadding from '~/components/BoxPadding'
import { fontSecondary } from '~/theme'
import BoxMargin from './BoxMargin'
import Action from '~/components/Actions'

const styles = (theme) => ({
  root: {
    transition: 'all .5s',
  },
  active: {
    '& $body': {
      overflow: 'unset',
      whiteSpace: 'unset',
      '& > *': {
        overflow: 'unset',
      },
    },
  },
  navbar: {
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& > *': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: 'black',
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(4),
      },
    },
  },
  [`theme${HEADER_THEMES.ENTITY}`]: {
    backgroundColor: theme.palette.background.entity,
  },
  [`theme${HEADER_THEMES.DEFAULT}`]: {
    backgroundColor: theme.palette.background.default,
  },
})

class Subnav extends React.Component {
  state = {
    active: false,
  }

  componentDidMount() {
    Router.events.on('routeChangeStart', this.resetHeader)
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.resetHeader)
  }

  resetHeader = () => {
    this.props.resetHeader()
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    const { classes, className, header } = this.props
    return (
      // we don't need the subnav on small screen b/c the menu has its own bar
      <Hidden smDown={!header.title} initialWidth="sm">
        <div
          className={clsx(
            classes.root,
            classes[`theme${header.theme}`],
            className,
            {
              [classes.active]: this.state.active,
            }
          )}
          onClick={() => this.toggleActive()}
        >
          <div className={classes.navbar}>
            <BoxMargin overflow="hidden">
              <BoxPadding>
                <div className={classes.body}>
                  <Typography
                    style={{
                      fontFamily: fontSecondary,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 700,
                      fontSize: '1rem',
                    }}
                  >
                    {header.title}
                  </Typography>
                  {header.subtitle && (
                    // marginTop in order to vert. align with the title which has a different font
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: '1rem',
                        fontFamily: fontSecondary,
                      }}
                    >
                      {header.subtitle}
                    </Typography>
                  )}
                  {header.chapter && header.chapter.title && (
                    <ChapterTitle
                      style={{
                        fontSize: '1rem',
                      }}
                      title={header.chapter.title}
                      number={header.chapter.number}
                    />
                  )}
                </div>
              </BoxPadding>
            </BoxMargin>
            <Box flexGrow={1} textAlign="right">
              {header.globalId && (
                <BoxPadding>
                  <FavoriteButton iconButton star objectId={header.globalId} />
                </BoxPadding>
              )}
              {header.actions && (
                <BoxPadding>
                  {header.actions.map((action) => (
                    <Action key={JSON.stringify(action)} {...action} />
                  ))}
                </BoxPadding>
              )}
            </Box>
          </div>
        </div>
      </Hidden>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state) => ({
      header: state.header,
    }),
    {
      resetHeader,
    }
  )
)(Subnav)
