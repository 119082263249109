import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '10vw',
    marginRight: '10vw',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}))

const BoxMargin = ({ className = '', ...props }) => {
  const classes = useStyles()
  return <Box {...props} className={clsx(classes.root, className)} />
}

export default BoxMargin
