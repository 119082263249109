import React from 'react'
import { useTranslation } from '~/i18n'
import { useApolloClient } from '@apollo/react-hooks'

const useI18N = () => {
  const { i18n } = useTranslation()
  const apolloClient = useApolloClient()

  const setLanguage = React.useCallback(
    async (ln) => {
      await i18n.changeLanguage(ln)
      await apolloClient.resetStore()
    },
    [i18n, apolloClient]
  )

  return { language: i18n.language, setLanguage }
}

export default useI18N
