import React from 'react'
import PropTypes from 'prop-types'
import romanize from 'romanize'
import Typo from '~/components/Typo'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  highlight: {
    color: theme.palette.secondary.main,
    fontSize: '1.3rem',
  },
}))
const ChapterTitle = ({
  slug,
  number,
  highlight,
  title,
  className,
  style = {},
}) => {
  const classes = useStyles()
  return (
    <Typo
      variant="sommaireTitle"
      className={clsx(classes.root, className, {
        [classes.highlight]: highlight,
      })}
      style={style}
    >
      {number > 0 && (
        <Box minWidth="1.5rem" paddingRight=".3rem" style={{ float: 'left' }}>
          {romanize(number)}.{' '}
        </Box>
      )}
      {title}
    </Typo>
  )
}

ChapterTitle.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  number: PropTypes.number.isRequired,
  highlight: PropTypes.bool,
  className: PropTypes.string,
}

export default ChapterTitle
