import React, { ReactChild } from 'react'
import dynamic from 'next/dynamic'
import Footer from '~/components/Footer'
import MenuAndHeader from '~/components/MenuAndHeader'
import { useSelector } from '~/reducers'

const SearchBar = dynamic(() => import('~/components/SearchBar'))

interface Props {
  children: ReactChild | ReactChild[]
}
const Layout = ({ children }: Props) => {
  const showSearchbar = useSelector((state) => state.search.show)
  return (
    <React.Fragment>
      <MenuAndHeader />
      <div>
        {children}
        {showSearchbar && <SearchBar />}
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Layout
